export default {
  props: {
    title: {
      type: Object,
    },
    video: {
      type: Object,
    },
    features: {
      type: Array,
    },
  },

  components: {
    hotVideoInline: () => import('~/components/Molecules/VideoInline/VideoInline.vue'),
    hotCarousel: () => import('~/components/Molecules/Carousel/Carousel.vue'),
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  computed: {
    hasTitle() {
      return this.title ? true : false
    },
    hasVideo() {
      return this.video ? true : false
    },
    hasFeatures() {
      return this.features ? true : false
    },
  },
}
