import { render, staticRenderFns } from "./ClubFeatures.vue?vue&type=template&id=587a8d6c&scoped=true"
import script from "./ClubFeatures.js?vue&type=script&lang=js&external"
export * from "./ClubFeatures.js?vue&type=script&lang=js&external"
import style0 from "./ClubFeatures.scss?vue&type=style&index=0&id=587a8d6c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587a8d6c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotTitle: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Title/Title.vue').default})
